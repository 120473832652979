var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { DataGrid, DataGridItem } from "@zct1989/vue-component";
import { Prop } from "vue-property-decorator";
import { State } from "vuex-class";
import DataBox from "~/components/common/data-box.vue";
import { UploadFileService } from "~/services/common-service/upload-file.service";
var CommunicateDetail = /** @class */ (function (_super) {
    __extends(CommunicateDetail, _super);
    function CommunicateDetail() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CommunicateDetail.prototype.downloadFile = function (row) {
        var _this = this;
        this.uploadFileService
            .getFileStreamById(row.fileId, row.fileName)
            .subscribe(function () { return _this.$message.success("下载成功"); });
    };
    __decorate([
        Dependencies(UploadFileService)
    ], CommunicateDetail.prototype, "uploadFileService", void 0);
    __decorate([
        State
    ], CommunicateDetail.prototype, "departmentList", void 0);
    __decorate([
        Prop({ required: true })
    ], CommunicateDetail.prototype, "rowDetail", void 0);
    CommunicateDetail = __decorate([
        Component({
            components: {
                DataGrid: DataGrid,
                DataGridItem: DataGridItem,
                DataBox: DataBox
            }
        })
    ], CommunicateDetail);
    return CommunicateDetail;
}(Vue));
export default CommunicateDetail;
