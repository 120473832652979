var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { TemplateService } from "~/services/manage-service/template.service";
var Skill = /** @class */ (function (_super) {
    __extends(Skill, _super);
    function Skill() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.dataSet = [];
        _this.loading = {
            state: false
        };
        _this.model = {
            name: '',
            content: '',
        };
        return _this;
    }
    /**
     * 关闭
     */
    Skill.prototype.close = function () {
        this.$emit('close');
    };
    Skill.prototype.fresh = function (val) {
        var _this = this;
        this.principalId = val;
        this.loading.state = true;
        this.templateService.getTemplate('TEL', this.principalId, this.loading).subscribe(function (data) {
            _this.dataSet = data;
            data.map(function (e) {
                if (e.isDefault === 'YES') {
                    _this.model.name = e.templateName;
                    _this.model.content = e.content;
                }
            });
        });
    };
    Skill.prototype.changeName = function (val) {
        var target = this.dataSet.filter(function (e) { return e.templateName === val; });
        if (target) {
            this.model.content = target[0].content;
        }
    };
    __decorate([
        Dependencies(TemplateService)
    ], Skill.prototype, "templateService", void 0);
    Skill = __decorate([
        Component({
            components: {}
        })
    ], Skill);
    return Skill;
}(Vue));
export default Skill;
