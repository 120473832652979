<template>
  <div>
    <div id="ctiIframe"></div>
    <!--<button  @click="reload()">ssssss</button>-->
  </div>
</template>

<script>
  import {StorageService} from "../../utils/storage.service";
  export default {
    name: 'Duyan',
    data () {
      return {
        accountId: null,
        token: null,
        isCallReady: false
      }
    },
    methods: {
      callMe (phone) {
        DYSDK.call(phone, (data) => {
        })
      },
    },
    mounted() {
      this.token = StorageService.getItem("duyan_token")
      this.accountId = StorageService.getItem("accountId");
      var se = document.createElement('script')
      se.id = 'dySdkScript'
      //需要先获取account_id和token
      se.setAttribute('url', `https://cti.duyansoft.com/ctibar.html?account_id=${this.accountId}&token=${this.token}&noNumberInput=false&noOpBtn=false`)
      se.setAttribute('ctype', 'mini')
      se.src = 'https://cti.duyansoft.com/syui/dysdk/dysdk2.min.js'

      // js 加载后执行
      se.onload = () => {
        DYSDK.ready(() => {

        })

        DYSDK.callReady(() => {

        })

        DYSDK.callConnecting((data) => {
          if (data.uuid) {
          }
        })

        DYSDK.callConfirm((data) => {
          if (data.uuid) {
          } else {
            this.$Message.error('不存在uuid')
          }
        })

        DYSDK.callFail((data) => {
        })

        DYSDK.callEnd((data) => {

        })

        DYSDK.callBridge((data) => {
        })

        DYSDK.getPhonelines((data) => {
        })

        DYSDK.init()
      }
      document.getElementById('ctiIframe').appendChild(se)
    }
  }
</script>
