var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { businessService } from '~/config/server';
import { NetService } from '~/utils/net.service';
import { Inject, Debounce } from "~/core/decorator";
import { Service } from "~/core/service";
var PersonalService = /** @class */ (function (_super) {
    __extends(PersonalService, _super);
    function PersonalService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 创建联系人地址
     * @param data 实体对象
     */
    PersonalService.prototype.addPersonalAddress = function (data, loading) {
        return this.netService.send({
            server: businessService.personalController.addPersonalAddress,
            data: data,
            loading: loading
        });
    };
    /**
     * 修改联系人地址
     * @param data
     * @param loading 加载等待
     */
    PersonalService.prototype.modifyPersonalAddress = function (data, loading) {
        return this.netService.send({
            server: businessService.personalController.modifyPersonalAddress,
            data: data,
            loading: loading
        });
    };
    /**
     * 创建联系人
     * @param data 实体对象
     */
    PersonalService.prototype.addPersonalContact = function (data, loading) {
        return this.netService.send({
            server: businessService.personalController.addPersonalContact,
            data: data,
            loading: loading
        });
    };
    /**
     * 修改联系人
     * @param data 实体对象
     */
    PersonalService.prototype.modifyPersonalContact = function (data, loading) {
        return this.netService.send({
            server: businessService.personalController.modifyPersonalContact,
            data: data,
            loading: loading
        });
    };
    /**
     * 联系人地址查询
     */
    PersonalService.prototype.findPersonalAddress = function (caseId, loading) {
        return this.netService.send({
            server: businessService.personalController.findPersonalAddress,
            data: {
                caseId: caseId
            },
            loading: loading
        });
    };
    /**
     * 联系人电话查询
     */
    PersonalService.prototype.findPersonalContact = function (caseId, loading) {
        return this.netService.send({
            server: businessService.personalController.findPersonalContact,
            data: {
                caseId: caseId
            },
            loading: loading
        });
    };
    /**
     * 更新联系人电话或地址状态
     * @param data
     * @param loading 加载等待
     */
    PersonalService.prototype.markPhoneOrAddressStatus = function (data, loading) {
        return this.netService.send({
            server: businessService.personalController.markPhoneOrAddressStatus,
            data: data,
            loading: loading
        });
    };
    /**
     * 修改联系人禁用状态
     * @param data 实体对象
     */
    PersonalService.prototype.modifyBanState = function (data, loading) {
        return this.netService.send({
            server: businessService.personalController.modifyBanState,
            data: data,
            loading: loading
        });
    };
    /**
     * 标记状态
     * @param data 实体对象
     */
    PersonalService.prototype.markStatus = function (data, loading) {
        return this.netService.send({
            server: businessService.personalController.markStatus,
            data: data,
            loading: loading
        });
    };
    /**
     * 删除联系人/地址
     */
    PersonalService.prototype.deletePersonalContact = function (id, type, loading) {
        return this.netService.send({
            server: businessService.personalController.deletePersonalContact,
            data: {
                id: id,
                type: type
            },
            loading: loading
        });
    };
    /**
     * 修改备注
     */
    PersonalService.prototype.modifyRemark = function (data, loading) {
        return this.netService.send({
            server: businessService.personalController.modifyRemark,
            data: data,
            loading: loading
        });
    };
    __decorate([
        Inject(NetService)
    ], PersonalService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], PersonalService.prototype, "addPersonalAddress", null);
    __decorate([
        Debounce()
    ], PersonalService.prototype, "addPersonalContact", null);
    __decorate([
        Debounce()
    ], PersonalService.prototype, "modifyPersonalContact", null);
    __decorate([
        Debounce()
    ], PersonalService.prototype, "modifyBanState", null);
    __decorate([
        Debounce()
    ], PersonalService.prototype, "markStatus", null);
    __decorate([
        Debounce()
    ], PersonalService.prototype, "deletePersonalContact", null);
    __decorate([
        Debounce()
    ], PersonalService.prototype, "modifyRemark", null);
    return PersonalService;
}(Service));
export { PersonalService };
