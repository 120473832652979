var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { commonService } from '~/config/server';
import { NetService } from '~/utils/net.service';
import { Inject, Debounce } from "~/core/decorator";
var OutboundService = /** @class */ (function () {
    function OutboundService() {
    }
    /**
     * 坐席登录
     */
    OutboundService.prototype.agentLogin = function (loading) {
        return this.netService.send({
            server: commonService.OutboundController.agentLogin,
            loading: loading
        });
    };
    /**
     * 坐席登出
     */
    OutboundService.prototype.agentLogout = function (loading) {
        return this.netService.send({
            server: commonService.OutboundController.agentLogout,
            loading: loading
        });
    };
    /**
     * 拨号
     */
    OutboundService.prototype.callOut = function (phoneNumber, personalContactId) {
        return this.netService.send({
            server: commonService.OutboundController.callOut,
            data: {
                phoneNo: phoneNumber,
                personalContactId: personalContactId
            }
        });
    };
    __decorate([
        Inject(NetService)
    ], OutboundService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], OutboundService.prototype, "agentLogin", null);
    __decorate([
        Debounce()
    ], OutboundService.prototype, "agentLogout", null);
    __decorate([
        Debounce()
    ], OutboundService.prototype, "callOut", null);
    return OutboundService;
}());
export { OutboundService };
