var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import { SortService } from "~/utils/sort.service";
import { PageService } from "~/utils/page.service";
import { Watch, Prop } from "vue-property-decorator";
import { Dependencies } from "~/core/decorator";
import { namespace } from "vuex-class";
import { CaseFollowRecordService } from "~/services/business-service/case-follow-record.service";
var collectionManageModule = namespace("collection-manage");
var Marking = /** @class */ (function (_super) {
    __extends(Marking, _super);
    function Marking() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = { state: false };
        _this.dataSet = [];
        _this.commentList = [];
        _this.deleteModel = {
            id: "",
            caseId: "",
            caseType: ""
        };
        return _this;
    }
    Marking.prototype.onCaseIdChanged = function (val) {
        val && this.refreshData();
    };
    Marking.prototype.created = function () { };
    Marking.prototype.mounted = function () { };
    Marking.prototype.refreshData = function () {
        this.getSearchComment();
    };
    Marking.prototype.commentDelete = function (id) {
        var _this = this;
        this.$confirm("是否确认删除?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        })
            .then(function () {
            _this.loading.state = true;
            _this.deleteModel.id = id;
            _this.deleteModel.caseId = _this.caseId;
            _this.deleteModel.caseType = _this.otherData.caseType;
            _this.caseFollowRecordService.deleteTag(_this.deleteModel)
                .subscribe(function (data) {
                _this.$message.success("删除成功!");
                _this.refreshData();
            });
        })
            .catch(function () { });
    };
    Marking.prototype.getSearchComment = function () {
        var _this = this;
        if (!this.caseId)
            return;
        var queryParamsModel = {
            caseId: this.caseId
        };
        this.loading.state = true;
        this.caseFollowRecordService.getAllTag(queryParamsModel, this.pageService, this.sortService, this.loading).subscribe(function (data) {
            _this.dataSet = data;
        });
    };
    Marking.prototype.activated = function () {
        this.refreshData();
    };
    __decorate([
        Dependencies(SortService)
    ], Marking.prototype, "sortService", void 0);
    __decorate([
        Dependencies(PageService)
    ], Marking.prototype, "pageService", void 0);
    __decorate([
        Dependencies(CaseFollowRecordService)
    ], Marking.prototype, "caseFollowRecordService", void 0);
    __decorate([
        collectionManageModule.State
    ], Marking.prototype, "otherData", void 0);
    __decorate([
        Prop()
    ], Marking.prototype, "isHistory", void 0);
    __decorate([
        Prop({ required: true, default: "" })
    ], Marking.prototype, "caseId", void 0);
    __decorate([
        Prop({ required: true, default: "" })
    ], Marking.prototype, "personalId", void 0);
    __decorate([
        Watch("caseId", { immediate: true })
    ], Marking.prototype, "onCaseIdChanged", null);
    Marking = __decorate([
        Component({
            components: {
                DataBox: DataBox
            }
        })
    ], Marking);
    return Marking;
}(Vue));
export default Marking;
