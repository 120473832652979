var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Dependencies } from "~/core/decorator";
import { PaymentCheckService } from "~/services/business-service/payment-check.service";
var collectionManageModule = namespace("collection-manage");
var PtpModify = /** @class */ (function (_super) {
    __extends(PtpModify, _super);
    function PtpModify() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.model = {
            reason: "",
            caseId: "",
            id: "",
            caseType: ""
        };
        _this.rules = {
            reason: [
                { required: true, message: "请输入申请说明", trigger: "blur" }
            ]
        };
        return _this;
    }
    PtpModify.prototype.close = function () {
        this.reset();
    };
    PtpModify.prototype.success = function () {
    };
    PtpModify.prototype.commit = function () {
        var _this = this;
        var form = this.$refs["form"];
        form.validate(function (valid) {
            if (!valid)
                return;
            _this.model.id = _this.ptpModel.id;
            _this.model.caseId = _this.caseId;
            _this.model.caseType = _this.otherData.caseType;
            _this.paymentCheckService.changeStatus(_this.model).subscribe(function (data) {
                _this.$message.success("转CP成功");
                _this.success();
                _this.close();
            });
        });
    };
    PtpModify.prototype.reset = function () {
        var form = this.$refs["form"];
        form.resetFields();
    };
    __decorate([
        Dependencies(PaymentCheckService)
    ], PtpModify.prototype, "paymentCheckService", void 0);
    __decorate([
        collectionManageModule.State
    ], PtpModify.prototype, "caseId", void 0);
    __decorate([
        collectionManageModule.State
    ], PtpModify.prototype, "otherData", void 0);
    __decorate([
        Prop()
    ], PtpModify.prototype, "ptpModel", void 0);
    __decorate([
        Emit("close")
    ], PtpModify.prototype, "close", null);
    __decorate([
        Emit("success")
    ], PtpModify.prototype, "success", null);
    PtpModify = __decorate([
        Component({
            components: {}
        })
    ], PtpModify);
    return PtpModify;
}(Vue));
export default PtpModify;
