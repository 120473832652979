import { render, staticRenderFns } from "./follow-record-phone-second.vue?vue&type=template&id=a8447082"
import script from "./follow-record-phone-second.vue?vue&type=script&lang=ts"
export * from "./follow-record-phone-second.vue?vue&type=script&lang=ts"
import style0 from "./follow-record-phone-second.vue?vue&type=style&index=0&id=a8447082&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports