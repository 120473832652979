var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { Prop, Watch } from "vue-property-decorator";
import { DataGrid, DataGridItem } from "@zct1989/vue-component";
import { IdCardService } from "~/services/repair-service/idCard.service";
import SimpleQueryInfo from "~/components/common/simple-query-info.vue";
var WorkIdCardQuery = /** @class */ (function (_super) {
    __extends(WorkIdCardQuery, _super);
    function WorkIdCardQuery() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.model = {
            idCard: ""
        };
        _this.backModel = {};
        _this.rules = {
            idCard: [{ required: true, message: "请输入身份证号", trigger: "blur" }, { validator: _this.$validator.idCard, trigger: 'blur' }],
        };
        return _this;
    }
    WorkIdCardQuery.prototype.onSaveIdCardChange = function (val) {
        if (val) {
            this.model.idCard = this.saveIdCard;
            this.search();
        }
    };
    WorkIdCardQuery.prototype.search = function () {
        var _this = this;
        var form = this.$refs["form"];
        form.validate(function (valid) {
            if (!valid)
                return;
            _this.idCardService.searchIdCardInformation(_this.model)
                .subscribe(function (data) {
                _this.backModel = data;
            });
        });
    };
    WorkIdCardQuery.prototype.mounted = function () {
        this.onSaveIdCardChange(this.saveIdCard);
    };
    __decorate([
        Dependencies(IdCardService)
    ], WorkIdCardQuery.prototype, "idCardService", void 0);
    __decorate([
        Prop()
    ], WorkIdCardQuery.prototype, "saveIdCard", void 0);
    __decorate([
        Watch('saveIdCard')
    ], WorkIdCardQuery.prototype, "onSaveIdCardChange", null);
    WorkIdCardQuery = __decorate([
        Component({
            components: {
                DataGrid: DataGrid,
                DataGridItem: DataGridItem,
                SimpleQueryInfo: SimpleQueryInfo
            }
        })
    ], WorkIdCardQuery);
    return WorkIdCardQuery;
}(Vue));
export default WorkIdCardQuery;
