var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Emit } from "vue-property-decorator";
import { Dependencies } from "~/core/decorator";
import { namespace } from "vuex-class";
import { ValidatorService } from "~/utils/validator.service";
import { RelationshipService } from "~/services/repair-service/relationship.service";
import SvgIcon from "~/components/common/svg-icon.vue";
var collectionManageModule = namespace("collection-manage");
var SocialDetail = /** @class */ (function (_super) {
    __extends(SocialDetail, _super);
    function SocialDetail() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = { state: false };
        _this.socialModels = [];
        _this.rules = {
            relationPersonName: [{ required: true, message: "请输入关系人姓名", trigger: "blur" }],
            relationPersonIdNo: [{ required: true, message: "请输入证件号", trigger: "blur" }, { validator: ValidatorService.idCard, trigger: "blur" }],
            relation: [{ required: true, message: "请输入关系", trigger: "blur" }],
        };
        return _this;
    }
    SocialDetail.prototype.close = function () {
        for (var i = 0; i < this.socialModels.length; i++) {
            var form = this.$refs["form-" + i];
            form[0].resetFields();
        }
        this.socialModels = [];
    };
    SocialDetail.prototype.refreshData = function () {
        this.socialModels.push({ relationPersonName: '', relationPersonIdNo: '', relation: '', remark: '', idNo: this.personalInfo.certificateNo, name: this.personalInfo.personalName });
    };
    SocialDetail.prototype.add = function () {
        this.socialModels.push({ relationPersonName: '', relationPersonIdNo: '', relation: '', remark: '', idNo: this.personalInfo.certificateNo, name: this.personalInfo.personalName });
    };
    SocialDetail.prototype.dele = function () {
        this.socialModels.pop();
    };
    SocialDetail.prototype.commit = function () {
        var _this = this;
        var backModel = [];
        var _loop_1 = function (i) {
            var form = this_1.$refs["form-" + i];
            form[0].validate(function (valid) {
                if (!valid)
                    return;
                if (backModel.length !== _this.socialModels.length) {
                    backModel.push(_this.socialModels[i]);
                }
            });
        };
        var this_1 = this;
        for (var i = 0; i < this.socialModels.length; i++) {
            _loop_1(i);
        }
        if (backModel.length === this.socialModels.length) {
            this.loading.state = true;
            this.relationshipService.insertBatch(backModel, this.loading)
                .subscribe(function (data) {
                _this.$message.success('新增成功!');
                _this.close();
            });
        }
    };
    __decorate([
        Dependencies(RelationshipService)
    ], SocialDetail.prototype, "relationshipService", void 0);
    __decorate([
        collectionManageModule.Getter
    ], SocialDetail.prototype, "personalInfo", void 0);
    __decorate([
        Emit("close")
    ], SocialDetail.prototype, "close", null);
    SocialDetail = __decorate([
        Component({
            components: { SvgIcon: SvgIcon }
        })
    ], SocialDetail);
    return SocialDetail;
}(Vue));
export default SocialDetail;
