var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from '~/core/decorator';
import { Emit, Prop } from "vue-property-decorator";
import { CaseApplyOperateService } from '~/services/domain-service/case-apply-operate.service';
var FileInfoModify = /** @class */ (function (_super) {
    __extends(FileInfoModify, _super);
    function FileInfoModify() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.model = {
            caseId: _this.caseId,
            getFileDeptId: '',
            applyType: 'CHECK_MATERIAL_APPLY',
            applyContent: '',
            applyRemark: '',
        };
        _this.rules = {};
        return _this;
    }
    FileInfoModify.prototype.close = function () { };
    FileInfoModify.prototype.mounted = function () {
    };
    FileInfoModify.prototype.commit = function () {
        var _this = this;
        var form = this.$refs['form'];
        form.validate(function (valid) {
            if (!valid)
                return;
            _this.loading.state = true;
            _this.caseApplyOperateService
                .caseApplyOperated(_this.model, _this.loading)
                .subscribe(function (data) {
                _this.$message.success('申请资料成功');
                _this.close();
            });
        });
    };
    __decorate([
        Dependencies(CaseApplyOperateService)
    ], FileInfoModify.prototype, "caseApplyOperateService", void 0);
    __decorate([
        Emit("close")
    ], FileInfoModify.prototype, "close", null);
    __decorate([
        Prop({})
    ], FileInfoModify.prototype, "caseId", void 0);
    FileInfoModify = __decorate([
        Component({})
    ], FileInfoModify);
    return FileInfoModify;
}(Vue));
export default FileInfoModify;
