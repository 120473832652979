var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import DataForm from '~/components/common/data-form.vue';
import CountryModify from '~/components/collection-manage/collection-application/country-modify.vue';
import { Dependencies } from "~/core/decorator";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { Prop } from "vue-property-decorator";
import { VillageCommitteeDataService } from "~/services/repair-service/village-committee-data.service";
import AddCountryData from "~/components/data-repair/country-data/add-country-data.vue";
var CountryQuery = /** @class */ (function (_super) {
    __extends(CountryQuery, _super);
    function CountryQuery() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = { state: false };
        _this.dataSet = [];
        _this.queryParamsModel = {
            keyword: ""
        };
        _this.dialog = { detail: false };
        return _this;
    }
    CountryQuery.prototype.refresh = function () {
        var _this = this;
        if (!this.queryParamsModel.keyword)
            return;
        this.loading.state = true;
        this.villageCommitteeDataService
            .searchByKeyword(this.queryParamsModel, this.pageService, this.sortService, this.loading).subscribe(function (data) {
            _this.dataSet = data;
        });
    };
    __decorate([
        Dependencies(PageService)
    ], CountryQuery.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], CountryQuery.prototype, "sortService", void 0);
    __decorate([
        Dependencies(VillageCommitteeDataService)
    ], CountryQuery.prototype, "villageCommitteeDataService", void 0);
    __decorate([
        Prop()
    ], CountryQuery.prototype, "isHistory", void 0);
    CountryQuery = __decorate([
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                CountryModify: CountryModify,
                AddCountryData: AddCountryData
            }
        })
    ], CountryQuery);
    return CountryQuery;
}(Vue));
export default CountryQuery;
