var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import TopCaseBaseInfo from "~/components/collection-manage/execution/top-case-base-info.vue";
import BarrageSlide from "~/components/common/barrage-slide.vue";
import { Layout, Dependencies } from "~/core/decorator";
import { Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { CollectionCaseService } from "~/services/domain-service/collection-case.service";
import { HistoryCaseService } from "~/services/domain-service/history-case.service";
import { CaseCollectorService } from "~/services/business-service/case-collector.service";
// 左侧信息
import CustomerInfo from "~/components/collection-manage/execution/case-base-info/customer-info.vue";
import CaseBaseInfo from "~/components/collection-manage/execution/case-base-info.vue";
import RelationQueryBase from "~/components/collection-manage/execution/relation-query-base.vue";
// 右侧信息
import FollowRecordBase from "~/components/collection-manage/execution/follow-record-base.vue";
import FollowQueryBase from "~/components/collection-manage/execution/follow-query-base.vue";
// 浮动面板
import PendingCollectionQuery from "~/components/collection-manage/execution/pending-collection-query.vue";
import CollectionHotkey from "~/components/collection-manage/execution/collection-hotkey.vue";
import CollectionAgentPhone from "~/components/collection-manage/execution/collection-agent-phone.vue";
var collectionManageModule = namespace("collection-manage");
var CollectionExecution = /** @class */ (function (_super) {
    __extends(CollectionExecution, _super);
    function CollectionExecution() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isApper = true;
        _this.accountInfo = [];
        _this.card = [];
        return _this;
    }
    CollectionExecution.prototype.onCaseIdChanged = function (val) {
        var _this = this;
        if (!val)
            return;
        // const queryService: any = this.isHistory ? this.historyCaseService : this.collectionCaseService;
        this.caseCollectorService.getCaseInfoById(this.caseId).subscribe(function (data) {
            console.log('sssssss:', data);
            _this.updateCaseInfo(data);
            _this.updateCardNo(data.cardNo);
            var certificateNo = data.certificateNo;
            _this.caseCollectorService.getCardInfoByIdCard(certificateNo, _this.caseId).subscribe(function (data) {
                _this.updateCardInfo(data);
                var overdueAmtTotalData = 0;
                data.map(function (x) {
                    overdueAmtTotalData += Number(x.overdueAmtTotal);
                });
                var leftAmtTotalData = 0;
                data.map(function (x) {
                    leftAmtTotalData += Number(x.leftAmt);
                });
                _this.updateOverdueAmtTotalInfo(overdueAmtTotalData);
                _this.updateLeftAmtTotalInfo(leftAmtTotalData);
                _this.updateAccountNumber(data.account);
                var map = {}, dest = [];
                var _loop_1 = function (i) {
                    var di = data[i];
                    if (!map[di.account]) {
                        dest.push({
                            no: di.account,
                            amt: di.leftAmt,
                        });
                        map[di.account] = di;
                    }
                    else {
                        dest.forEach(function (x) {
                            if (x.no === di.account)
                                x.amt += di.leftAmt;
                        });
                        for (var j = 0; j < dest.length; j++) {
                            var dj = dest[j];
                            if (dj.no === di.no) {
                                dj.amt = dj.amt + di.amt;
                                dj.data.push(di);
                                break;
                            }
                        }
                    }
                };
                for (var i = 0; i < data.length; i++) {
                    _loop_1(i);
                }
                _this.updateAccountInfo(dest);
            });
        });
    };
    CollectionExecution.prototype.isDisplay = function (val) {
        this.isApper = val;
    };
    Object.defineProperty(CollectionExecution.prototype, "isHistory", {
        /**
         * 是否是历史案件进入，通过OhterData 传入
         */
        get: function () {
            return this.queryOtherData("isHistory");
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CollectionExecution.prototype, "isFast", {
        /**
         * 是否快速查询进入
         */
        get: function () {
            return this.queryOtherData("isFast");
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Dependencies(CollectionCaseService)
    ], CollectionExecution.prototype, "collectionCaseService", void 0);
    __decorate([
        Dependencies(HistoryCaseService)
    ], CollectionExecution.prototype, "historyCaseService", void 0);
    __decorate([
        Dependencies(CaseCollectorService)
    ], CollectionExecution.prototype, "caseCollectorService", void 0);
    __decorate([
        collectionManageModule.State
    ], CollectionExecution.prototype, "caseId", void 0);
    __decorate([
        collectionManageModule.Getter
    ], CollectionExecution.prototype, "queryOtherData", void 0);
    __decorate([
        collectionManageModule.Mutation
    ], CollectionExecution.prototype, "updateCaseInfo", void 0);
    __decorate([
        collectionManageModule.Mutation
    ], CollectionExecution.prototype, "updateCardInfo", void 0);
    __decorate([
        collectionManageModule.Mutation
    ], CollectionExecution.prototype, "updateOverdueAmtTotalInfo", void 0);
    __decorate([
        collectionManageModule.Mutation
    ], CollectionExecution.prototype, "updateLeftAmtTotalInfo", void 0);
    __decorate([
        collectionManageModule.Mutation
    ], CollectionExecution.prototype, "updateCardNo", void 0);
    __decorate([
        collectionManageModule.Mutation
    ], CollectionExecution.prototype, "updateAccountNumber", void 0);
    __decorate([
        collectionManageModule.Mutation
    ], CollectionExecution.prototype, "updateAccountInfo", void 0);
    __decorate([
        Watch("caseId", { immediate: true })
    ], CollectionExecution.prototype, "onCaseIdChanged", null);
    CollectionExecution = __decorate([
        Layout("default"),
        Component({
            components: {
                TopCaseBaseInfo: TopCaseBaseInfo,
                BarrageSlide: BarrageSlide,
                CustomerInfo: CustomerInfo,
                CaseBaseInfo: CaseBaseInfo,
                RelationQueryBase: RelationQueryBase,
                FollowRecordBase: FollowRecordBase,
                FollowQueryBase: FollowQueryBase,
                PendingCollectionQuery: PendingCollectionQuery,
                CollectionHotkey: CollectionHotkey,
                CollectionAgentPhone: CollectionAgentPhone,
            },
        })
    ], CollectionExecution);
    return CollectionExecution;
}(Vue));
export default CollectionExecution;
