var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import PtpModify from "./repayment-operation-base/ptp-modify.vue";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { Watch, Prop } from "vue-property-decorator";
import { Dependencies } from "~/core/decorator";
import { namespace } from "vuex-class";
import { PaymentCheckService } from "~/services/business-service/payment-check.service";
var collectionManageModule = namespace("collection-manage");
var RepaymentOperation = /** @class */ (function (_super) {
    __extends(RepaymentOperation, _super);
    function RepaymentOperation() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.currentName = "panel-1";
        _this.balanceUpdateDataSet = [];
        _this.bankReconciliationDataSet = [];
        _this.selectionList = [];
        _this.cardInfo = {};
        _this.queryParamsCPModel = {
            baseCaseId: ""
        };
        _this.queryParamsPTPModel = {
            baseCaseId: ""
        };
        _this.PTPToCP = {
            paymentId: "",
            caseId: "",
            caseType: ""
        };
        _this.ptpModel = {};
        _this.dialog = {
            ptpToCp: false
        };
        _this.loading = {
            state: false
        };
        return _this;
    }
    RepaymentOperation.prototype.onCaseIdChanged = function (val) {
        val && this.refreshData();
    };
    RepaymentOperation.prototype.refreshData = function () {
        this.getCPData();
        this.getPTPData();
    };
    RepaymentOperation.prototype.getCPData = function () {
        var _this = this;
        if (this.caseId == "")
            return;
        this.paymentCheckService.queryCpRecord(this.caseId, this.pageService, this.loading)
            .subscribe(function (data) {
            _this.balanceUpdateDataSet = data;
        });
    };
    RepaymentOperation.prototype.getPTPData = function () {
        var _this = this;
        this.paymentCheckService.queryPtpRecord(this.caseId, this.pageService, this.loading)
            .subscribe(function (data) {
            _this.bankReconciliationDataSet = data;
        });
    };
    RepaymentOperation.prototype.PTPToCPClick = function (row) {
        this.ptpModel = row;
    };
    RepaymentOperation.prototype.PTPBouncedClick = function (row) {
        var _this = this;
        this.$confirm("\u60A8\u786E\u5B9A\u8981\u8DF3\u7968\u4E48\u5417\uFF1F", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        }).then(function () {
            _this.PTPToCP.id = row.id;
            _this.PTPToCP.caseId = _this.caseId;
            _this.PTPToCP.caseType = _this.otherData.caseType;
            _this.loading.state = true;
            _this.paymentCheckService.bouncedCheck(_this.PTPToCP, _this.loading)
                .subscribe(function (data) {
                _this.$message.success("跳票成功!"), _this.refreshData();
            }, function (error) {
                _this.$message.error("跳票失败");
            });
        });
    };
    RepaymentOperation.prototype.activated = function () {
        this.refreshData();
    };
    __decorate([
        Dependencies(PageService)
    ], RepaymentOperation.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], RepaymentOperation.prototype, "sortService", void 0);
    __decorate([
        Dependencies(PaymentCheckService)
    ], RepaymentOperation.prototype, "paymentCheckService", void 0);
    __decorate([
        collectionManageModule.State
    ], RepaymentOperation.prototype, "otherData", void 0);
    __decorate([
        Prop({ required: true, default: "" })
    ], RepaymentOperation.prototype, "caseId", void 0);
    __decorate([
        Watch("caseId", { immediate: true })
    ], RepaymentOperation.prototype, "onCaseIdChanged", null);
    RepaymentOperation = __decorate([
        Component({
            components: {
                DataBox: DataBox,
                PtpModify: PtpModify
            }
        })
    ], RepaymentOperation);
    return RepaymentOperation;
}(Vue));
export default RepaymentOperation;
