import { render, staticRenderFns } from "./drag-dialog.vue?vue&type=template&id=731e0cbb&scoped=true"
import script from "./drag-dialog.vue?vue&type=script&lang=ts"
export * from "./drag-dialog.vue?vue&type=script&lang=ts"
import style0 from "./drag-dialog.vue?vue&type=style&index=0&id=731e0cbb&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "731e0cbb",
  null
  
)

export default component.exports