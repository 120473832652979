var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import DataForm from "~/components/common/data-form.vue";
import { PageService } from "~/utils/page.service";
import { Dependencies } from "~/core/decorator";
import { DataGrid, DataGridItem } from "@zct1989/vue-component";
import { namespace } from "vuex-class";
import { ArchiveDataService } from "~/services/repair-service/archive-data.service";
import { UploadFileService } from "~/services/common-service/upload-file.service";
import ViewImage from "~/components/common/view-image.vue";
var collectionManageModule = namespace("collection-manage");
var FileInfoDetails = /** @class */ (function (_super) {
    __extends(FileInfoDetails, _super);
    function FileInfoDetails() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.model = {};
        _this.dataSet = {};
        _this.loading = {
            state: false
        };
        _this.dialog = { view: false };
        _this.url = "";
        return _this;
    }
    Object.defineProperty(FileInfoDetails.prototype, "kosekiData", {
        /**
         * 户籍
         */
        get: function () {
            return this.dataSet.kosekiData || {};
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FileInfoDetails.prototype, "socialSecurityData", {
        /**
         * 社保
         */
        get: function () {
            return this.dataSet.socialSecurityData || {};
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FileInfoDetails.prototype, "socialSecurityDataFile", {
        /**
         * 社保附件
         */
        get: function () {
            if (!!this.dataSet.socialSecurityData) {
                return this.dataSet.socialSecurityData.credentialSet;
            }
            else {
                return [];
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FileInfoDetails.prototype, "relationshipList", {
        /**
         * 关系及联系方式
         */
        get: function () {
            return this.dataSet.relationshipList || [];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FileInfoDetails.prototype, "kosekiRemarkList", {
        /**
         * 备注
         */
        get: function () {
            return this.dataSet.kosekiRemarkList || [{ remark: '' }];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FileInfoDetails.prototype, "specialTransferDataModel", {
        /**
         * 特调
         */
        get: function () {
            if (Object.keys(this.dataSet).length) {
                return this.dataSet.specialTransferDataModel.credentialSet;
            }
            else {
                return [];
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FileInfoDetails.prototype, "familyPlanningDataModel", {
        /**
         * 计生
         */
        get: function () {
            if (Object.keys(this.dataSet).length) {
                return this.dataSet.familyPlanningDataModel.credentialSet;
            }
            else {
                return [];
            }
        },
        enumerable: false,
        configurable: true
    });
    FileInfoDetails.prototype.mounted = function () {
        this.refreshData();
    };
    FileInfoDetails.prototype.refreshData = function () {
        var _this = this;
        this.loading.state = true;
        this.archiveDataService
            .get(this.personalInfo.certificateNo, this.loading)
            .subscribe(function (data) {
            _this.dataSet = data;
        });
    };
    /**
     * 根据文件id获取文件url
     */
    FileInfoDetails.prototype.getFileUrlById = function (fileId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.uploadFileService
                .getFileUrlById(fileId)
                .subscribe(function (data) { return resolve(data); }, function (error) { return reject(error); });
        });
    };
    FileInfoDetails.prototype.viewImage = function (url) {
        this.url = url;
        this.dialog.view = true;
    };
    __decorate([
        Dependencies(PageService)
    ], FileInfoDetails.prototype, "pageService", void 0);
    __decorate([
        Dependencies(ArchiveDataService)
    ], FileInfoDetails.prototype, "archiveDataService", void 0);
    __decorate([
        Dependencies(UploadFileService)
    ], FileInfoDetails.prototype, "uploadFileService", void 0);
    __decorate([
        collectionManageModule.Getter
    ], FileInfoDetails.prototype, "personalInfo", void 0);
    FileInfoDetails = __decorate([
        Component({
            components: {
                DataGrid: DataGrid,
                DataGridItem: DataGridItem,
                DataBox: DataBox,
                DataForm: DataForm,
                ViewImage: ViewImage
            }
        })
    ], FileInfoDetails);
    return FileInfoDetails;
}(Vue));
export default FileInfoDetails;
