var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import { Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { DataGrid, DataGridItem } from "@zct1989/vue-component";
var collectionManageModule = namespace("collection-manage");
var CallConfirm = /** @class */ (function (_super) {
    __extends(CallConfirm, _super);
    function CallConfirm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.flg = false;
        _this.loading = {
            state: false
        };
        return _this;
    }
    CallConfirm.prototype.cancel = function () {
        this.$emit('close');
    };
    CallConfirm.prototype.confrim = function () {
        var that = this;
    };
    __decorate([
        Prop()
    ], CallConfirm.prototype, "rowData", void 0);
    CallConfirm = __decorate([
        Component({
            components: {
                DataBox: DataBox,
                DataGrid: DataGrid,
                DataGridItem: DataGridItem
            }
        })
    ], CallConfirm);
    return CallConfirm;
}(Vue));
export default CallConfirm;
