import PhoneQuery from "./phone-query.vue";
import AddressQuery from "./address-query.vue";
import SocialQuery from "./social-query.vue";
import BalanceUpdateQuery from "./balance-update-query.vue";
import RepaymentOperation from "./repayment-operation.vue";
import LogRecords from "./log-records.vue";
import Marking from "./marking.vue";
import CountryQuery from "~/components/collection-manage/execution/collection-hotkey/country-query.vue";
import FileInfoQuery from "~/components/collection-manage/execution/collection-hotkey/file-info-query.vue";
import CoUrgingQuery from "~/components/collection-manage/execution/collection-hotkey/co-urging-query.vue";
export var components = {
    PhoneQuery: PhoneQuery,
    AddressQuery: AddressQuery,
    SocialQuery: SocialQuery,
    BalanceUpdateQuery: BalanceUpdateQuery,
    RepaymentOperation: RepaymentOperation,
    LogRecords: LogRecords,
    Marking: Marking,
    CountryQuery: CountryQuery,
    FileInfoQuery: FileInfoQuery,
    CoUrgingQuery: CoUrgingQuery,
};
export var config = [
    {
        label: "电话",
        name: "PhoneQuery",
        auth: 730,
    },
    {
        label: "地址",
        name: "AddressQuery",
        auth: 731,
    },
    // {
    //   label: "社会关系",
    //   name: "SocialQuery",
    //   auth: 727
    // },
    {
        label: "预约还款",
        name: "BalanceUpdateQuery",
        auth: 732,
    },
    {
        label: "CP/PTP记录",
        name: "RepaymentOperation",
        auth: 733,
    },
    // {
    //   label: "村委查询",
    //   name: "CountryQuery",
    //   auth: 721
    // },
    // {
    //   label: "档案资料",
    //   name: "FileInfoQuery",
    //   auth: 722
    // },
    {
        label: "协调记录",
        name: "CoUrgingQuery",
        auth: 734,
    },
    {
        label: "分案记录",
        name: "LogRecords",
        auth: 735,
    },
    {
        label: "标注",
        name: "Marking",
        auth: 736,
    },
];
