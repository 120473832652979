var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { dataimpService } from "~/config/server";
import { NetService } from "~/utils/net.service";
import { Inject } from "~/core/decorator";
import { FilterService } from "~/utils/filter.service";
var CaseSearchRecordService = /** @class */ (function () {
    function CaseSearchRecordService() {
    }
    /**
     * 查询对账记录
     */
    CaseSearchRecordService.prototype.caseBillRecord = function (data, loading) {
        return this.netService.send({
            server: dataimpService.caseSearchRecordController.caseBillRecord,
            data: data,
            loading: loading,
        });
    };
    /**
     * 查询更新记录
     */
    CaseSearchRecordService.prototype.caseUpdateRecord = function (data, loading) {
        return this.netService.send({
            server: dataimpService.caseSearchRecordController.caseUpdateRecord,
            data: data,
            loading: loading,
        });
    };
    /**
     * 查询还款纪录 调收执行页面卡信息下拉明细
     */
    CaseSearchRecordService.prototype.searchPaymentRecord = function (id) {
        return this.netService.send({
            server: dataimpService.caseSearchRecordController.searchPaymentRecord,
            data: {
                baseCaseId: id,
            },
        });
    };
    /**
     * 查询对账列表
     */
    CaseSearchRecordService.prototype.searchBillList = function (data, page, sort, loading) {
        var payDate = FilterService.dateRanageFormat(data.payDate, "yyyy-MM-dd");
        return this.netService.send({
            server: dataimpService.caseSearchRecordController.searchBillList,
            data: {
                principalId: data.principalId,
                personalName: data.personalName,
                certificateNo: data.certificateNo,
                batchNumber: data.batchNumber,
                payDateStart: payDate.start,
                payDateEnd: payDate.end,
                payAmtStart: data.payAmt.min,
                payAmtEnd: data.payAmt.max,
                cardNo: data.cardNo,
                departCode: data.departCode,
                handsNumber: data.handsNumber,
                collectorUsername: data.collectorUsername,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 查询还款纪录 调收执行页面卡信息下拉明细
     */
    CaseSearchRecordService.prototype.queryBillsearchByPrincipal = function (data) {
        var payDate = FilterService.dateRanageFormat(data.payDate, "yyyy-MM-dd");
        return this.netService.send({
            server: dataimpService.caseSearchRecordController.queryBillsearchByPrincipal,
            data: {
                principalId: data.principalId,
                personalName: data.personalName,
                certificateNo: data.certificateNo,
                batchNumber: data.batchNumber,
                payDateStart: payDate.start,
                payDateEnd: payDate.end,
                payAmtStart: data.payAmt.min,
                payAmtEnd: data.payAmt.max,
                cardNo: data.cardNo,
                departCode: data.departCode,
                handsNumber: data.handsNumber,
                collectorUsername: data.collectorUsername,
            },
        });
    };
    __decorate([
        Inject(NetService)
    ], CaseSearchRecordService.prototype, "netService", void 0);
    return CaseSearchRecordService;
}());
export { CaseSearchRecordService };
